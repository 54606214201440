import React from "react";
import theme from "theme";
import { Theme, Link, Image, Box, Section, Text } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"impressum"} />
		<Helmet>
			<title>
				Impressum
			</title>
			<meta name={"description"} content={"A cognitive editor for Java and Kotlin"} />
			<meta property={"og:title"} content={"Cognitive Editor"} />
			<meta property={"og:description"} content={"A cognitive editor for Java and Kotlin\n"} />
		</Helmet>
		<Image
			position="absolute"
			bottom="auto"
			md-width="600px"
			md-top="300px"
			lg-right="-200px"
			lg-top="0px"
			src="https://uploads.quarkly.io/6123949e957019001e284458/images/backgroung-hero.svg?v=2021-09-21T22:07:12.314Z"
			right="0px"
			top="130px"
			z-index="-10"
		/>
		<Section background="linear-gradient(264.47deg, #000848 29.39%, #000848 93.49%)" padding="36px 0 36px 0" quarkly-title="Header">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				empty-border-width="1px"
				width="70%"
				align-items="center"
				sm-width="50%"
				display="flex"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-style="solid"
				empty-border-color="LightGray"
			>
				<Components.QuarklycommunityKitMobileSidePanel md-z-index="9">
					<Override slot="Button Text" md-display="none" />
					<Override slot="Button Icon" md-color="--white" md-font="36px sans-serif" />
					<Override slot="Cross" md-color="--dark" md-font="36px sans-serif" />
					<Override slot="Wrapper" md-z-index="9" />
					<Override
						slot="Content"
						md-justify-content="flex-start"
						background="rgba(255, 255, 255, 0)"
						justify-content="center"
						align-items="center"
						padding="16px 0px 16px 16px"
						md-background="--color-light"
						md-z-index="9"
					/>
					<Override slot="Children" display="flex" align-items="center" justify-content="center" />
					<Box
						justify-content="flex-end"
						md-flex-direction="column"
						md-margin="40px 0px 13px 0px"
						md-font="--headline4"
						display="flex"
						align-items="center"
					>
						<Link
							color="--white"
							margin="0px 20px 0px 20px"
							md-margin="0px 0px 13px 0px"
							md-font="--lead"
							md-color="--indigo"
							href="/index"
							font="normal 600 18px/1.5 --fontFamily-googleInter"
							text-decoration-line="initial"
						>
							Home
						</Link>
						<Link
							transition="opacity 0.3s ease 0s"
							md-margin="0px 0px 13px 0px"
							href="/index#features"
							font="--base"
							text-decoration-line="initial"
							hover-opacity="1"
							md-font="--lead"
							md-color="--indigo"
							color="--grey"
							margin="0px 20px 0px 20px"
							opacity="0.8"
						>
							Features
						</Link>
						<Link
							color="--grey"
							margin="0px 20px 0px 20px"
							transition="opacity 0.3s ease 0s"
							md-margin="0px 0px 13px 0px"
							md-font="--lead"
							href="/index#public-beta"
							font="--base"
							text-decoration-line="initial"
							opacity="0.8"
							hover-opacity="1"
							md-color="--indigo"
						>
							Public Beta
						</Link>
						<Link
							hover-opacity="1"
							md-font="--lead"
							md-color="--indigo"
							font="--base"
							color="--grey"
							opacity="0.8"
							transition="opacity 0.3s ease 0s"
							md-margin="0px 0px 13px 0px"
							href="#about"
							text-decoration-line="initial"
							margin="0px 8px 0px 20px"
						>
							About
						</Link>
					</Box>
				</Components.QuarklycommunityKitMobileSidePanel>
			</Box>
		</Section>
		<Section background="linear-gradient(264.47deg, #000848 29.39%, #000848 93.49%)" padding="36px 0 80px 0" quarkly-title="HeroBlock">
			<Override
				slot="SectionContent"
				md-z-index="7"
				flex-direction="row"
				position="relative"
				z-index="9"
				md-flex-wrap="wrap"
			/>
			<Box
				empty-border-width="1px"
				align-items="flex-start"
				md-width="100%"
				display="flex"
				flex-direction="column"
				empty-border-color="LightGray"
				width="70%"
				justify-content="center"
				padding="0px 180px 0px 0px"
				lg-padding="0px 90px 0px 0px"
				empty-min-height="64px"
				empty-border-style="solid"
				empty-min-width="64px"
				md-padding="0px 0px 0px 0px"
			>
				<Text
					letter-spacing="1%"
					lg-text-align="left"
					lg-font="normal 700 40px/1.2 &quot;Inter&quot;, sans-serif"
					md-font="normal 700 28px/1.2 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 16px 0px"
					font="--headline1"
					color="--white"
					sm-font="normal 500 30px/1.2 &quot;Inter&quot;, sans-serif"
				>
					Impressum
				</Text>
			</Box>
		</Section>
		<Section>
			<Text margin="0px 0px 0px 0px">
				monochromata UG (haftungsbeschränkt)<br />
				Friedrichstraße 95<br />
				10117 Berlin<br />
				Germany<br />
				<br/>
				info at monochromata.de<br />
				+49 30 531 126 09<br/>
				<br/>
				Authorized representative (managing director):<br/>
				Sebastian Lohmeier<br/>
				<br/>
				VAT identification number: DE370048667<br/>
				<br/>
				Entry in the commercial register:<br/>
				Amtsgericht Charlottenburg HRB 267515 B<br/>
				<br/>
				Responsible according to §18 Medienstaatsvertrag:<br/>
				Sebastian Lohmeier<br/>
				c/o monochromata<br/>
				Friedrichstraße 95<br/>
				10117 Berlin<br/>
				<br/>
			</Text>
		</Section>
		<Section background="--color-indigo" padding="20px 0 40px 0" md-padding="40px 0 0px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-border-width="1px"
				display="flex"
				md-width="100%"
				md-align-items="center"
				flex-direction="column"
				padding="16px 16px 16px 16px"
				md-justify-content="center"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="25%"
			>
				<Link
					text-decoration-line="initial"
					transition="opacity 0.3s ease 0s"
					hover-opacity="1"
					margin="0px 0px 10px 0px"
					font="--base"
					href="/"
					color="--white"
					opacity=".8"
				>
					Home
				</Link>
				<Link
					opacity=".8"
					text-decoration-line="initial"
					transition="opacity 0.3s ease 0s"
					hover-opacity="1"
					margin="0px 0px 10px 0px"
					font="--base"
					href="https://monochromata.de/doc/"
					color="--white"
				>
					Documentation
				</Link>
				<Link
					opacity=".8"
					text-decoration-line="initial"
					transition="opacity 0.3s ease 0s"
					hover-opacity="1"
					margin="0px 0px 10px 0px"
					font="--base"
					href="https://app.slack.com/client/T07MX4MKU0M/C07MZTSLUTV"
					color="--white"
				>
					Slack
				</Link>
				<Link
					opacity=".8"
					text-decoration-line="initial"
					transition="opacity 0.3s ease 0s"
					hover-opacity="1"
					margin="0px 0px 10px 0px"
					font="--base"
					href="https://github.com/monochromata/cognitive-editor/releases"
					color="--white"
				>
					Releases on GitHub
				</Link>
				<Link
					color="--white"
					opacity=".8"
					text-decoration-line="initial"
					transition="opacity 0.3s ease 0s"
					hover-opacity="1"
					margin="0px 0px 10px 0px"
					font="--base"
					href="https://github.com/monochromata/cognitive-editor/issues"
				>
					Issues on GitHub
				</Link>
			</Box>
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				width="25%"
				display="flex"
				md-width="100%"
				md-justify-content="center"
				empty-border-width="1px"
				empty-border-style="solid"
				flex-direction="column"
				padding="16px 16px 16px 16px"
				md-align-items="center"
			>
				<Link
					color="--white"
					opacity=".8"
					text-decoration-line="initial"
					transition="opacity 0.3s ease 0s"
					hover-opacity="1"
					margin="0px 0px 10px 0px"
					font="--base"
					href="/privacy"
				>
					Privacy policy
				</Link>
				<Link
					color="--white"
					opacity=".8"
					text-decoration-line="initial"
					transition="opacity 0.3s ease 0s"
					hover-opacity="1"
					margin="0px 0px 10px 0px"
					font="--base"
					href="/eula"
				>
					EULA
				</Link>
				<Link
					color="--white"
					opacity=".8"
					text-decoration-line="initial"
					transition="opacity 0.3s ease 0s"
					hover-opacity="1"
					margin="0px 0px 10px 0px"
					font="--base"
					href="/impressum"
				>
					Impressum
				</Link>
			</Box>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6123949e957019001e284456"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});